import Dialog from "@atoms/dialog/dialog"
import styled, { css } from "styled-components"

export const videoCallStyle = css`
  .card {
    width: 1200px;
    height: 750px;
    max-width: 80%;
    max-height: 90%;
    padding: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    background-color: ${({ theme }) => theme.palette.common.black};
    border-radius: 10px;
    overflow: visible;

    transition: 0.3s ease-in-out transform;
    animation: ${({ theme }) => theme.animations.zoomIn} 0.5s forwards;

    h3 {
      margin-bottom: 20px !important;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      width: 100%;
      max-width: 100%;
      height: 96vh;
      max-height: none;
      top: 12px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    background-color: ${({ theme }) => theme.palette.common.black};
  }
`

export const ConfirmStyled = styled.div`
  .confirmDesc {
    font-size: 14px;
  }

  .confirmButton {
    padding: 12px 15px;

    &:first-child {
      float: right;
    }

    &:last-child {
      float: right;
    }
  }
`

export const IframeStyled = styled.iframe`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: none;

  &.hidden {
    opacity: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    border-radius: 0px;
  }
`
